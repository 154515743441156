"use strict";

// externi knihovny
import "nodelist-foreach-polyfill";

// basket
import { basket } from "./basket/index.js";

// order
import { order } from "./order/index.js";

// modal
import { modal } from "./modal";

// BasketAndOrder
class BasketAndOrder {
  constructor() {
    // register basket.aftersave callbacks - BEGIN
    basket.registerAfterSaveCallback(this.setBasketContentToOrder, this);

    // prvotni zavolani naplneni obsahu kosiku
    this.setBasketContentToOrder();

    // nastaveni callbacku po odeslani formulare objednavky
    order.registerSumbitCallback("success", this.orderSubmitSuccess, this);
    order.registerSumbitCallback("error", this.orderSubmitError, this);
  }

  // nastavovani pole basket formulare z kosiku
  setBasketContentToOrder() {
    let basketContentJson = !basket.isEmpty()
      ? JSON.stringify(basket.asArray())
      : "";
    order.setBasket(basketContentJson);
  }

  // callback po uspesnem odeslani objednavky
  orderSubmitSuccess(xhr) {
    // reset kosiku
    basket.empty();

    // reset objednavkoveho furmulare
    order.reset();

    // zavreni okna
    modal.close();

    // response text
    try {
      alert(JSON.parse(xhr.responseText).message);
    } catch (err) {
      console.dir(err);
      console.dir(xhr);
      alert("Chyba objednávky");
    }
  }

  // callback po neuspesnem odeslani objednavky
  orderSubmitError() {
    // zobrazeni infa
    alert("OBJEDNÁVKU SE NEPODAŘILO ODESLAT");
  }
}

// autostart
document.addEventListener("DOMContentLoaded", () => {
  new BasketAndOrder();
});
