"use strict";

// tohle nahraje i soubory, ktere nejsou primo linkovane z entrypointu
import "./images/*.{png,jpg,jpeg,gif,svg}";

// UIkit
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";

// loads the Icon plugin
UIkit.use(Icons);

// import stylu
import "./index.scss";

// basketAndOrder
import "./basket-and-order/index.js";

// form ajax submit
import "./scripts/form-ajax-submit";

// form ajax submit
import "./scripts/feedback-form";
