"use strict";

// formulare s pozadavkem na odesilani AJAXem
const forms = document.querySelectorAll(".ajax-submit");

// request
const xhr = new XMLHttpRequest();

// externi knihovny
import "nodelist-foreach-polyfill";

forms.forEach(form => {
  form.addEventListener(
    "submit",
    e => {
      // data formulare
      let formData = new FormData(form);

      // metoda odeslani
      let method = form.getAttribute("method")
        ? form.getAttribute("method")
        : "GET";

      // zpracovani response
      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            try {
              form.submitSuccess(xhr);
            } catch (error) {
              //
            }
          } else {
            try {
              form.submitError(xhr);
            } catch (error) {
              //
            }
          }
        }
      };

      // odeslani requestu
      xhr.open(method, form.getAttribute("action"), true);
      xhr.send(formData);
      e.preventDefault();
    },
    false
  );
});
