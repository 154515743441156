"use strict";

export default function itemTemplate(item) {
  return `
    <div class="item">
      <div class="text">
        <strong>${item.title}</strong>
        ${
          item.additional_data.quantity
            ? `<small>(${item.additional_data.quantity})</small>`
            : ""
        }
        ${renderAllergens(item.additional_data.allergens)}
        ${
          item.additional_data.notice
            ? `<p class="notice">${item.additional_data.notice}</p>`
            : ""
        }

      </div>

      <div class="count">
        <input
          type="number"
          class="uk-input"
          data-basket-set-count="${item.id}"
          min="0"
          value="${item.count}" />
        <button
          type="button"
          data-basket-remove="${item.id}">
          <span data-uk-icon="icon: trash;"></span>
        </button>
      </div>

      <div class="price">${item.totalPrice},—</div>
    </div>
  `;
}

function renderAllergens(allergens) {
  return '';
  let html = "";
  if (allergens.length) {
    html += '<sup class="allergens">';
    allergens.forEach((allergen, i) => {
      html += `
        <a href="#"
          class="alergen"
          data-tooltip="<strong>${allergen.title}</strong>
          ${allergen.description}">
          ${allergen.code})</a>${i + 1 < allergens.length ? ", " : ""}
      `;
    });
    html += "</sup>";
  }
  return html;
}
