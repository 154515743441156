"use strict";

// klic v localStorage
const localStorageKey = "basket";

// actionTriggers
const actionTriggers = () => {
  return {
    addItem: document.querySelectorAll("[data-basket-add]"),
    removeItem: document.querySelectorAll("[data-basket-remove]"),
    setItemCount: document.querySelectorAll("[data-basket-set-count]")
  };
};

// display listeners
const displayListeners = () => {
  return {
    basketEmptyClass: document.querySelectorAll("[data-basket-empty-class]"),
    itemList: document.querySelectorAll("[data-basket-item-list]"),
    value: document.querySelectorAll("[data-basket-display]")
  };
};

// externi knihovny
import "jspolyfill-array.prototype.find";
import "nodelist-foreach-polyfill";

// komponenty
import Item from "./item";
import itemTemplate from "./item-template";

class Basket {
  constructor() {
    // nacteni items z localStorage - END
    this.items = this.__load();

    // pole aftersave callbacku
    this.afterSaveCallbacks = [
      [this.__processDisplayListeners, this],
      [this.__registerActionTriggers, this]
    ];

    // prvotni zavolani after save callbacku
    this.__processAfterSaveCallbacks();
  }

  // ulozeni do localStorage
  save() {
    // vycisteni items od polozek co maji mene nez 1 kus
    this.items = this.items.filter(item => item.count > 0);

    // ulozeni do localstorage
    localStorage.setItem(localStorageKey, JSON.stringify(this.items));

    // zavolani aftersave callbacku
    this.__processAfterSaveCallbacks();
  }

  // register aftersave callback
  registerAfterSaveCallback(fce, context) {
    this.afterSaveCallbacks.push([fce, context]);
  }

  // vraci item podle id
  getItem(id) {
    return this.items.find(item => item.id === id);
  }

  // pridani itemu
  addItem(id, title, price, count = 1, additional_data = {}) {
    price = parseFloat(price);
    count = parseInt(count);
    if (this.getItem(id)) {
      this.setItemCount(id, this.getItem(id).count + count);
    } else {
      this.items.push(new Item(id, title, price, count, additional_data));
      this.save();
    }
  }

  // nastaveni poctu kusu
  setItemCount(id, count = 1) {
    count = parseInt(count);
    this.items = this.items.map(item => {
      item.count = item.id === id ? count : item.count;
      return item;
    });
    this.save();
  }

  // vymazani itemu
  removeItem(id) {
    this.setItemCount(id, 0);
  }

  // zvysit pocet kusu
  increaseItemCount(id) {
    this.items = this.items.map(item => {
      item.count = item.id === id ? ++item.count : item.count;
      return item;
    });
    this.save();
  }

  // snizit pocet kusu
  decreaseItemCount(id) {
    this.items = this.items.map(item => {
      item.count = item.id === id ? --item.count : item.count;
      return item;
    });
    this.save();
  }

  // celkova cena kosiku
  get price() {
    return this.items.reduce((a, b) => a + b.totalPrice, 0);
  }

  // vymazani vsech polozek
  empty() {
    this.items = [];
    this.save();
  }

  // vraci true pokud je kosik prazdny
  isEmpty() {
    return this.items.length ? false : true;
  }

  // vraci jako statcke pole / plain object
  asArray() {
    let items = this.items.map(item => {
      return item.asArray();
    });
    return { items: items, price: this.price }
  }

  // nacteni dat z localStorage
  __load() {
    let items = localStorage.getItem(localStorageKey)
      ? JSON.parse(localStorage.getItem(localStorageKey))
      : [];
    return items.map(item => {
      return new Item(
        item.id,
        item.title,
        item.price,
        item.count,
        item.additional_data
      );
    });
  }

  // zavola after save callbacky
  __processAfterSaveCallbacks() {
    this.afterSaveCallbacks.map(callback => {
      let fce = callback[0];
      let context = callback[1];
      if (context) {
        fce.call(context);
      } else {
        fce();
      }
    });
  }

  // resi problem s tim, ze lze vicekrat registrovat stejny listener
  // a ten se pak vola vicekrat
  __addEventListenerUnique(elm, eventName, eventId, fce) {
    let registerdListeners = elm.dataset.uniqueEventListenersRegistered
      ? JSON.parse(elm.dataset.uniqueEventListenersRegistered)
      : [];
    if (registerdListeners.indexOf(eventId) === -1) {
      elm.addEventListener(eventName, e => {
        fce(e);
      });
      registerdListeners.push(eventId);
      elm.dataset.uniqueEventListenersRegistered = JSON.stringify(
        registerdListeners
      );
    }
  }

  // registruje triggery akci
  __registerActionTriggers() {
    // basketAdd
    actionTriggers().addItem.forEach(elm => {
      this.__addEventListenerUnique(elm, "click", "addItem", e => {
        let product = JSON.parse(e.currentTarget.dataset.basketAdd);
        this.addItem(
          product.id,
          product.title,
          product.price,
          1,
          product.additional_data
        );
      });
    });

    // removeItem
    actionTriggers().removeItem.forEach(elm => {
      this.__addEventListenerUnique(elm, "click", "removeItem", e => {
        this.removeItem(e.currentTarget.dataset.basketRemove);
      });
    });

    // setItemCount
    actionTriggers().setItemCount.forEach(elm => {
      this.__addEventListenerUnique(elm, "change", "setItemCount", e => {
        this.setItemCount(
          e.currentTarget.dataset.basketSetCount,
          e.currentTarget.value
        );
      });
    });
  }

  // zpracuje zobrazovaci listenery
  __processDisplayListeners() {
    // basketEmptyClass
    displayListeners().basketEmptyClass.forEach(elm => {
      if (this.isEmpty()) {
        elm.classList.add(elm.dataset.basketEmptyClass);
      } else {
        elm.classList.remove(elm.dataset.basketEmptyClass);
      }
    });

    // basketItemList
    displayListeners().itemList.forEach(elm => {
      let html = "";
      this.items.forEach(item => {
        html += itemTemplate(item);
      });
      elm.innerHTML = html;
    });

    // value
    displayListeners().value.forEach(elm => {
      let valueFor = {
        variable: elm.dataset.basketDisplay,
        defaultValue: "",
        format: "string",
        result: NaN
      };

      try {
        valueFor.variable = JSON.parse(elm.dataset.basketDisplay).var
          ? JSON.parse(elm.dataset.basketDisplay).var
          : valueFor.variable;
        valueFor.defaultValue = JSON.parse(elm.dataset.basketDisplay).default
          ? JSON.parse(elm.dataset.basketDisplay).default
          : valueFor.defaultValue;
        valueFor.format = JSON.parse(elm.dataset.basketDisplay).format
          ? JSON.parse(elm.dataset.basketDisplay).format
          : valueFor.format;
      } catch (err) {
        /* */
      }

      switch (valueFor.variable) {
        /* tady lze resit vyjimky - nejake slozitejsi manipulace */
        /*
        case "":
          break;
        */
        default:
          valueFor.result =
            valueFor.variable in this
              ? this[valueFor.variable]
              : valueFor.defaultValue;
          break;
      }

      if (valueFor.format === "string") {
        valueFor.result = valueFor.result.toLocaleString();
      }
      elm.innerHTML = valueFor.result;
    });
  }
}

// export
export const basket = new Basket();
