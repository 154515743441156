"use strict";

const form = document.getElementById("contact_form");

const resultContainer = document.getElementById(
  "contact_form_result_container"
);

const modalCloseButton = document.querySelector(
  "#contact-form-container .uk-modal-close-default"
);

// zpracovani odeslani - v poradku
form.submitSuccess = xhr => {
  resultContainer.innerHTML = xhr.responseText;
  form.reset();
};

// zpracovani odeslani - chyba
form.submitError = xhr => {
  console.log("error");
  console.dir(xhr);
};

modalCloseButton.addEventListener("click", () => {
  resultContainer.innerHTML = "";
});
