"use strict";

export default class Item {
  constructor(id, title, price, count = 1, additional_data = {}) {
    this.id = id;
    this.title = title;
    this.price = parseFloat(price);
    this.count = parseInt(count);
    this.additional_data = additional_data;
  }

  get totalPrice() {
    return this.price * this.count;
  }

  asArray() {
    let item = JSON.parse(JSON.stringify(this));
    item.totalPrice = this.totalPrice;
    return item;
  }
}
