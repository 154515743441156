"use strict";

// orderform
const orderForm = document.getElementById("order");

// field zony
const shippingZoneField = orderForm
  ? orderForm.querySelector("input[name='data[shipping_zone]']")
  : [];

// fieldy pro nastaveni zony
const shippingZoneTableFields = orderForm
  ? orderForm.querySelectorAll(
      "input[type='radio'][name='shipping_zones_table_input[]']"
    )
  : [];

// odesilaci tlacitko
const submitButton = orderForm
  ? orderForm.querySelector("#basket-and-order-window button[type='submit']")
  : null;

// field pro obsah kosiku
const basketField = orderForm
  ? orderForm.querySelector("[name='data[basket]")
  : [];

// field data vytvoreni objednavky
const orderCreatedField = orderForm
  ? orderForm.querySelector("[name='data[created]']")
  : [];

// externi knihovny
import "nodelist-foreach-polyfill";
import * as Sentry from "@sentry/browser";

class Order {
  constructor() {
    // automaticke nastavovani hodnoty dorucovaci zony z tabulky
    this.__initSetShippingZoneFieldFromShippingZoneTable();

    // validace formulare
    this.__validate();

    // zapnuti automaticke kontroly validity
    this.__initValidationCheck();

    // aktivace nastaveni data vytvoreni pro stisknuti odesilaciho tlacitka
    if (submitButton) {
      submitButton.addEventListener("click", () => {
        let d = new Date();
        orderCreatedField.value =
          d.getFullYear() +
          "-" +
          leadingZero(d.getMonth() + 1) +
          "-" +
          leadingZero(d.getDate()) +
          " " +
          leadingZero(d.getHours()) +
          ":" +
          leadingZero(d.getMinutes()) +
          ":" +
          leadingZero(d.getSeconds());
      });
    }

    // submit callbacky - BEGIN
    this.submitCallbacks = {
      success: [],
      error: []
    };
    orderForm.submitSuccess = xhr => {
      this.__processSubmitCallbacks(this.submitCallbacks.success, xhr);
    };
    orderForm.submitError = xhr => {
      this.__processSubmitCallbacks(this.submitCallbacks.error, xhr);
    };
    // submit callbacky - END
  }

  // nastaveni obsahu kosiku
  setBasket(val) {
    basketField.value = val;
    this.__validate();
  }

  // reset formulare
  reset() {
    orderForm.reset();
  }

  // register submit callback
  registerSumbitCallback(type, fce, context) {
    this.submitCallbacks[type].push([fce, context]);
  }

  // aktivuje automaticke nastavovani zony z tabulky zon
  __initSetShippingZoneFieldFromShippingZoneTable() {
    shippingZoneTableFields.forEach(elm => {
      elm.addEventListener("click", e => {
        shippingZoneField.value = e.currentTarget.value;
      });
    });
  }

  // nastavuje automaticke volani validace fieldu formulare
  __initValidationCheck() {
    let listenedEvents = ["change", "keyup"];
    var fields = document.forms["order"].elements;
    for (var i = 0; i < fields.length; i++) {
      var field = fields[i];
      listenedEvents.forEach((listenedEvent) => {
        field.addEventListener(listenedEvent, () => {
          this.__validate();
        });
      });
    }
  }

  // validace formulare
  __validate() {
    let isValid = true;

    // standardni fieldy
    var fields = document.forms["order"].elements;
    for (var i = 0; i < fields.length; i++) {
      var field = fields[i];
      if (!field.checkValidity()) {
        isValid = false;
      }
    }

    // hiden fieldy - z nejakeho duvodu nefunguje required pro hidden fieldy
    if (basketField.value.trim() == "") {
      isValid = false;
    }
    if (shippingZoneField.value.trim() == "") {
      isValid = false;
    }

    if (submitButton) {
      submitButton.disabled = !isValid;
    }
  }

  // spusteni submit callbacku
  __processSubmitCallbacks(callbacks, xhr) {
    callbacks.map(callback => {
      let fce = callback[0];
      let context = callback[1];
      if (context) {
        fce(xhr).call(context);
      } else {
        fce(xhr);
      }
    });
  }

  // vraci fieldy order formulare nebo zpusobi reload stranky
  // na tom vznikaly chyby ze formular neexistoval
  __getOrderFormFields() {
    try {
      return orderForm.elements;
    } catch (error) {
      Sentry.captureException(error);
      location.reload();
    }
  }

}

// pridani nuly na zacatek pokud jde o jednociferne cislo
function leadingZero(str) {
  str = str.toString();
  if (str.length < 2) {
    str = "0" + str;
  }
  return str;
}


// export
export const order = new Order();
