"use strict";

// externi knihovny
import "nodelist-foreach-polyfill";

// modal win
export const modal = document.getElementById("basket-and-order-window");

// modal openery
const modalOpeners = document.querySelectorAll(".basket-window-open");
modalOpeners.forEach(elm => {
  elm.addEventListener("click", () => {
    modal.style.display = "block";
  });
});

// modal closery
const modalClosers = document.querySelectorAll(".basket-window-close");
modalClosers.forEach(elm => {
  elm.addEventListener("click", () => {
    modal.style.display = "none";
  });
});

// pridani metod pro modal - BEGIN
modal.open = () => {
  modalOpeners[0].click();
};

modal.close = () => {
  modalClosers[0].click();
};
// pridani metod pro modal - END
